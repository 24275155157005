import React from 'react'
import {BrowserRouter, Routes, Route } from "react-router-dom"
import Header from './Components/Header'
import Home from './views/Home'
import Status from './views/Status'

function App() {
  return (
    <div>
      <Header  />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/status" element={<Status />} />
        </Routes>
      </BrowserRouter>

    
      
    </div>
  );
}

export default App
