import React from 'react'


const Imprimir = (props) => {
    return <div class="card-footer  d-flex justify-content-end">
    <button type="button"  onClick={() => window.print()} class="btn btn-primary btn-sm">Imprimir</button></div>
}

export default Imprimir;



