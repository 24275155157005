import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (<>
    <footer className={`col-md-5 container text-center`}>
      <small>
        S.O.S Cidadão | CNPJ 30.299.902/0001-31
        <br />
        Rua Capitão Pedrosa, 87 A. São Cristóvão - Santa Cruz do Capibaribe - PE
        / CEP: 55.194-100 <br />
        Fone: (81) 3705-1313. Dúvidas, sugestões e
        reclamações: (81) 9 9153-6652.
        <br />

      </small>
    </footer>

    <span className={`${styles.brasfirst} brasfirst text-center`}>
      Powered by {" "}
      <a href="https://www.brasfirst.com" rel="noreferrer" target="_blank">
        Brasfirst
      </a>{" "}
      for a better web.
    </span></>
  );
};

export default Footer;
