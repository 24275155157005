import React from "react"

import Imprimir from './Imprimir'

const Conclusao = (props) => {
  const { result } = props;

  const numberFormat = (value) =>
  new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  }).format(value);


  return (
    <>
      <ul className="list-group p-2">
        <div className="alert alert-success p-2" role="alert">
          Sua solicitação foi enviada com sucesso! você recebera uma copia da
          solicitação via e-mail em instantes.
        </div>

        <li className="list-group-item d-flex justify-content-between bg-light">
          <div className="">
            <h6 className="my-0">
              Solicitação n° {result && result.protocolo}
            </h6>
          </div>
          <span>Status: {result && result.status}</span>
        </li>

        <li className="list-group-item d-flex justify-content-between lh-condensed"> 
        <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">N° Item</th>
                    <th scope="col">Descrição Item Pauta da Fiscal</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Valor Unitário</th>
                    <th scope="col">Valor Total</th>
                    <th scope="col">Imposto ( {props.config.taxa_imposto}% )</th>
                  </tr>
                </thead>

                <tbody>
                  {props.selecionados[0] &&
                    props.selecionados.map((item) => (
                      <tr key={item.id}>
                        <th scope="row">{item.id}</th>
                        <td>{item.descricao}</td>
                        <td>{item.quantidade}</td>
                        <td>{parseFloat(item.preco).toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}</td>
                        <td>{parseFloat(item.total).toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}</td>
                        <td>{item.imposto.toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
        </li>

      
        {props.config &&  <li className="list-group-item d-flex justify-content-between  lh-condensed flex-column" dangerouslySetInnerHTML={{ __html: props.config.dados_pagamento}} /> }

        {props.config &&  <li className="dados_bancarios_li  list-group-item d-flex flex-column justify-content-between lh-condensed" dangerouslySetInnerHTML={{ __html: props.config.dados_bancarios}} /> }


        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div className="">
            <h6 className="my-0">Imposto da nota: </h6>
          </div>
          <span>{result && result.imposto.toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}</span>
        </li>

        <li className="list-group-item d-flex justify-content-between lh-condensed">
          <div className="">
            <h6 className="my-0">Taxa de serviço: </h6>
          </div>
          <span>{result && result.taxa.toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}</span>
        </li>
        <li className="list-group-item d-flex justify-content-between bg-light">
          <span>Total a pagar (BRL)</span>
          <strong>{result && result.total.toLocaleString("pt-BR", { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}</strong>
        </li>
      </ul>

     <Imprimir />

    </>
  );
};

export default Conclusao;
