import React from "react";

const Endereco = (props) => {

  function maskCEP(c) {

    let re = /^([\d]{2})\.?([\d]{3})\-?([\d]{3})/;


      return c.replace(re,"$1.$2-$3");

  }
  function mask (e) {

      setTimeout(function() {
        let v = maskCEP(e.target.value);

        if (v != e.target.value) {
          e.target.value = v;
        }
      }, 1);
  }

  const handleChange = ({ target }) => {
    const { id, value } = target;

    if(id === "cep") {
      props.setEndereco({ ...props.endereco, [id]: value.replace(/\D/g, '') });
     
    } else {
      props.setEndereco({ ...props.endereco, [id]: value });
    }

  };

  return (
    <div className="pb-5 row g-3">
      <div className="col-md-7">
        <label for="endereco" className="form-label">
          Endereço:
        </label>
        <input
          type="text"
          className={"form-control destinatario " +  (props.endereco.endereco.length !== 0 && "is-valid"  )}
          
          value={props.endereco.endereco}
          id="endereco"
          onChange={handleChange}
          required
        />
      </div>

      <div className="col-md-2">
        <label for="n" className="form-label">
          N°:
        </label>
        <input
          type="number"
          className={"form-control destinatario " +  (props.endereco.n.length !== 0 && "is-valid"  )}
          
          value={props.endereco.n}
          id="n"
          onChange={handleChange}
          required
        />
      </div>

      <div className="col-md-3">
        <label for="complemento" className="form-label">
          Complemento:
        </label>
        <input
          type="text"
          className={"form-control destinatario " +  (props.endereco.complemento.length !== 0 && "is-valid"  )}
          
          value={props.endereco.complemento}
          id="complemento"
          onChange={handleChange}
        />
      </div>

      <div className="col-md-3">
        <label for="bairro" className="form-label">
          Bairro:
        </label>
        <input
          type="text"
          className={"form-control destinatario " +  (props.endereco.bairro.length !== 0 && "is-valid"  )}
          
          value={props.endereco.bairro}
          id="bairro"
          onChange={handleChange}
          required
        />
      </div>

      <div className="col-md-3">
        <label for="cidade" className="form-label">
          Cidade:
        </label>
        <input
          type="text"
          className={"form-control destinatario " +  (props.endereco.cidade.length !== 0 && "is-valid"  )}
          value={props.endereco.cidade}
          id="cidade"
          onChange={handleChange}
          required
        />
      </div>

      

      <div className="col-md-3">
        <label for="cep" className="form-label">
          Cep:
        </label>
        <input
          type="text"
          
          className={"form-control destinatario " +  (props.endereco.cep.length > 3 && "is-valid"  )}
          value={props.endereco.cep}
          id="cep"
          onChange={handleChange}
          required
        />
      </div>

      <div className="col-md-3">
        <label for="estado" className="form-label">
          Estado:
        </label>

        <select value={props.endereco.estado} id="estado"  onChange={handleChange} className={"form-control destinatario " +  (props.endereco.estado.length !== 0 && "is-valid"  )} aria-label="Selecione seu estado" required>
          <option selected value="">Selecione o Estado (UF)</option>
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espirito Santo</option>
          <option value="GO">Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MT">Mato Grosso</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
        </select>


      </div>

    </div>
  );
};

export default Endereco;
