import React from 'react'
import styles from './Header.module.css'
import logo from '../assets/img/logo.svg'

const Header = (props) => {
    return <header className={styles.header}>

        <h1>
            <img src={logo} alt="" />
        </h1>
        <small>Facilitando o seu dia a dia<br/>soscidadao.net<br/>(81) 9 9153-6652</small>


    </header>
}

export default Header