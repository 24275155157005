import React from "react";


function validaCpfCnpj(val) {

  val = val.replace(/\D/g, "");
  if (val.length === 11) {
      var cpf = val.trim();
   
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');
      
      var v1 = 0;
      var v2 = 0;
      var aux = false;
      var i;
      var p;
      var p1, p2;
      
      for (i = 1; cpf.length > i; i++) {
          // eslint-disable-next-line
          if (cpf[i - 1] != cpf[i]) {
              aux = true;   
          }
      } 
      // eslint-disable-next-line
      if (aux == false) {
          return false; 
      } 
      
      for (i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p; 
      } 
      
      v1 = ((v1 * 10) % 11);
      // eslint-disable-next-line
      if (v1 == 10) {
          v1 = 0; 
      }
      // eslint-disable-next-line
      if (v1 != cpf[9]) {
          return false; 
      } 
      
      for (i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p; 
      } 
      
      v2 = ((v2 * 10) % 11);
      // eslint-disable-next-line
      if (v2 == 10) {
          v2 = 0; 
      }
      // eslint-disable-next-line
      if (v2 != cpf[10]) {
          return false; 
      } else {   
          return true; 
      }// eslint-disable-next-line
  } else if (val.length == 14) {
      var cnpj = val.trim();
      
      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', ''); 
      cnpj = cnpj.split(''); 

      
      v1 = 0;

        v2 = 0;
      aux = false;
      
      for (i = 1; cnpj.length > i; i++) {
          // eslint-disable-next-line 
          if (cnpj[i - 1] != cnpj[i]) {  
              aux = true;   
          } 
      } 
      // eslint-disable-next-line
      if (aux == false) {  
          return false; 
      }
      
      for (i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
          if (p1 >= 2) {  
              v1 += cnpj[i] * p1;  
          } else {  
              v1 += cnpj[i] * p2;  
          } 
      } 
      
      v1 = (v1 % 11);
      
      if (v1 < 2) { 
          v1 = 0; 
      } else { 
          v1 = (11 - v1); 
      } 
      // eslint-disable-next-line
      if (v1 != cnpj[12]) {  
          return false; 
      } 
      
      for (i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
          if (p1 >= 2) {  
              v2 += cnpj[i] * p1;  
          } else {   
              v2 += cnpj[i] * p2; 
          } 
      }
      
      v2 = (v2 % 11); 
      
      if (v2 < 2) {  
          v2 = 0;
      } else { 
          v2 = (11 - v2); 
      } 
      // eslint-disable-next-line
      if (v2 != cnpj[13]) {   
          return false; 
      } else {  
          return true; 
      }
  } else {
      return false;
  }
}


const Destinatario = (props) => {



  const handleChange = ({ target }) => {
    const { id, value } = target;

    if(id === 'cpf_cnpj') {
        let valid =  validaCpfCnpj(value);
        if (valid === true) {
            props.setCheckCPF(true);
        } else {
            props.setCheckCPF(false);
        }
        props.setDestinatario({ ...props.destinatario, [id]: value.replace(/\D/g, '') });
    } else {
        props.setDestinatario({ ...props.destinatario, [id]: value });
    }
    
  };

  function mphone(v) {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  function mask (e) {
    
    setTimeout(function() {
      var v = mphone(e.target.value);
      if (v != e.target.value) {
        e.target.value = v;
      }
    }, 1);
}

  return (
    <div className="pb-3 row g-3">
      <h4 className="mb-3">Dados do Destinatário: (Comprador da Mercadoria)</h4>

      <div className="col-md-6">
        <label for="cpf_cnpj" className="form-label">
          CPF ou CNPJ:
        </label>
        <input
          type="text"
          className={"form-control destinatario " +  ((props.destinatario.cpf_cnpj.length !== 0) && (props.checkCPF === true  ? "is-valid" : "is-invalid")  ) }
          value={props.destinatario.cpf_cnpj}
          id="cpf_cnpj"
          pattern="([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})"
          onChange={handleChange}
          required
        />
      </div>

      <div className="col-md-6">
        <label for="inscricao_estadual" className="form-label">
          Inscrição Estadual (Se houver):
        </label>
        <input
          type="number"
          className={"form-control destinatario " +  (props.destinatario.inscricao_estadual.length !== 0 && "is-valid"  )}

          value={props.destinatario.inscricao_estadual}
          id="inscricao_estadual"
          onChange={handleChange}
        />
      </div>
      <div className="col-md-6">
        <label for="nome_razao_social" className="form-label">
          Nome ou Razão Social:
        </label>
        <input
          type="text"
          className={"form-control destinatario " +  (props.destinatario.nome_razao_social.length !== 0 && "is-valid"  )}
          value={props.destinatario.nome_razao_social}
          id="nome_razao_social"
          onChange={handleChange}
          required
        />

      </div>

    </div>
  );
};

export default Destinatario;
