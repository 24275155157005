import React from 'react'

const Transportadora = (props) => {

    const handleChange = ({ target }) => {
        const { id, value } = target;
        props.setTransportadora({ ...props.transportadora, [id]: value });
    }

    function mphone(v) {
        var r = v.replace(/\D/g, "");
        r = r.replace(/^0/, "");
        if (r.length > 10) {
          r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 5) {
          r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
          r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
          r = r.replace(/^(\d*)/, "($1");
        }
        return r;
      }

    function mask (e) {
    
        setTimeout(function() {
          var v = mphone(e.target.value);
          if (v != e.target.value) {
            e.target.value = v;
          }
        }, 1);
    }


    return <div className="row g-3">
              <h4 className="mb-3">Dados de Transporte: </h4>
                <div className="col-md-4">
                        <label for="nome_da_transportadora" className="form-label">Nome da Transportadora:</label>
                        <input type="text" className={"form-control transportadora "  +  (props.transportadora.nome_da_transportadora.length !== 0 && "is-valid"  )} 
                         value={props.transportadora.nome_da_transportadora} id="nome_da_transportadora"  onChange={handleChange} />
                    </div>

                    <div className="col-md-4">
                        <label for="quantidade_de_volumes" className="form-label">Quantidade de Volumes:</label>
                        <input type="number" className={"form-control transportadora "  +  (props.transportadora.quantidade_de_volumes.length !== 0 && "is-valid"  )}    value={props.transportadora.quantidade_de_volumes} id="quantidade_de_volumes" onChange={handleChange}  />
                    </div>
                    <div className="col-md-4">
                        <label for="peso" className="form-label">Peso:</label>
                        <input type="number" className={"form-control transportadora "  +  (props.transportadora.peso.length !== 0 && "is-valid"  )}   value={props.transportadora.peso} id="peso"  onChange={handleChange}  />
                    </div>

                    <div className="col-md-12">
                        <label for="frete_destinatario" className="form-label">Frete por conta do destinatario? &nbsp;</label>
                        <label for="frete_destinatario_sim">Sim &nbsp;</label>
                        <input type="checkbox" id="frete_destinatario" name="frete_destinatario"  value="Sim"  required={props?.transportadora?.frete_destinatario === "" ? true : false} checked={props?.transportadora?.frete_destinatario === "Sim"} onClick={handleChange} />
                        <label for="frete_destinatario_nao">&nbsp; Não &nbsp;</label>
                        <input type="checkbox" id="frete_destinatario" name="frete_destinatario" value="Não" required={props?.transportadora?.frete_destinatario === "" ? true : false} checked={props?.transportadora?.frete_destinatario === "Não"}  onClick={handleChange} />

                        <hr />
                    </div>

                    <div className="col-md-12">
                        <label for="tel" className="form-label">Informe Telefone de Contato para Auxiliar a Transportadora na Entrega da Mercadoria:</label>
                        <input  onKeyUp={mask}  type="tel" placeholder="(00) 0 0000-0000"  className={"form-control " +  (props.transportadora.telefone_de_contato_para_entrega_da_mercadoria.length > 10  && "is-valid"  )}  value={props.transportadora.telefone_de_contato_para_entrega_da_mercadoria} id="telefone_de_contato_para_entrega_da_mercadoria"  onChange={handleChange}  required/>
                    </div>
                    

                    <div className="col-md-12">
                        <h6 className="mb-3">Informações complementares de transporte:</h6>
                        <input type="text" placeholder="" className={"form-control transportadora "  +  (props.transportadora.infComplementares.length !== 0 && "is-valid"  )}   value={props.transportadora.infComplementares} id="infComplementares"  onChange={handleChange}  />
                    </div>
    </div>
}

export default Transportadora;