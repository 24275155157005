import React, { useState } from 'react'

const Solicitante = (props) => {


  const isDateValid = (inputDate) => {
    const [day, month, year] = inputDate.split('/');
    const formattedDate = `${month}/${day}/${year}`;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const parsedInputDate = new Date(formattedDate);
    const diffTime = parsedInputDate - today;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    console.log(inputDate)
    console.log(parsedInputDate)
    
    const maxDaysAhead = 5; // Máximo de dias à frente permitido

    return (
      parsedInputDate >= today &&
      diffDays <= maxDaysAhead &&
      !isNaN(parsedInputDate)
    );
  };

  function dateMask(e) {
    e.target.value = e.target.value.replace(/[^\d/]/g, '')
    let len = e.target.value.length;



    if (e.keyCode < 47 || e.keyCode > 57) {
      console.log(e.keyCode)
      e.preventDefault();
    }

    if (e.keyCode == 8) {
      e.target.value.substring(0, -1)
      return
    }

    if (len !== 1 || len !== 3) {
      if (e.keyCode == 47) {
        console.log(e.keyCode)
        e.preventDefault();
      }
    }
    if (len === 2) {
      e.target.value += '/';
    }
    if (len === 5) {
      e.target.value += '/';
    }

  }


  function mphone(v) {
    var r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  function mask(e) {

    setTimeout(function () {
      var v = mphone(e.target.value);
      if (v != e.target.value) {
        e.target.value = v;
      }
    }, 1);
  }

  const handleChange = ({ target }) => {
    const { id, value } = target;
    props.setSolicitante({ ...props.solicitante, [id]: value });
  }


  



  return <div className="py-5 row g-3">
    <h4 className="mb-3">Informe seus dados: (Dados de quem está preenchendo essa solicitação...)</h4>
    <div className="col-md-4">
      <label for="nome_completo" className="form-label">Nome:</label>
      <input type="text" className={"form-control " + (props.solicitante.nome_completo.length !== 0 && "is-valid")} value={props.solicitante.nome_completo} id="nome_completo" onChange={handleChange} required />
    </div>

    <div className="col-md-4">
      <label for="tel" className="form-label">Telefone:</label>
      <input onKeyUp={mask} type="tel" placeholder="(00) 0 0000-0000" className={"form-control " + (props.solicitante.telefone_whatsapp.length > 10 && "is-valid")} value={props.solicitante.telefone_whatsapp} id="telefone_whatsapp" onChange={handleChange} required />
    </div>
    <div className="col-md-4">
      <label for="email" className="form-label">E-mail:</label>
      <input type="email" placeholder="Por gentileza informe um e-mail válido" className={"form-control " + (props.solicitante.email.length !== 0 && "is-valid")} value={props.solicitante.email} id="email" onChange={handleChange} required />
    </div>
    {/*
    <div className="col-md-4">
      <label for="date_saida" className="form-label">Data de sáida da mercadoria:</label>
      <input type="tel" minLength="10" pattern="\d{1,2}/\d{1,2}/\d{4}" maxLength="10" onKeyUp={dateMask} placeholder="Ex: 01/01/2021" value={props.solicitante.data_saida} className={"form-control " + (props.solicitante.data_saida.length !== 0 && !props.avisoNota && "is-valid") + (props.avisoNota === true && " is-invalid")} id="data_saida" onChange={handleChange} required />
      {false && <small style={{ color: "var(--bs-danger)" }}><small className="m-0" style={{ color: "var(--bs-danger)" }} >A nota fiscal tem o prazo de apenas 5 dias, para trafegar dentro do estado de PE e 15 dias nos demais estados.</small></small>}
</div> */}

<div className="col-md-4">
      <label htmlFor="data_saida" className="form-label">Data de saída da mercadoria:</label>
      <input
        type="tel"
        minLength="10"
        maxLength="10"
        pattern="\d{1,2}/\d{1,2}/\d{4}"
        placeholder="Ex: 01/01/2021"
        value={props.solicitante.data_saida}
         onKeyUp={dateMask}
        className={
          'form-control ' +
          (props.solicitante.data_saida ? ( isDateValid(props.solicitante.data_saida) ? 'is-valid' : 'is-invalid') : '')
        }
        id="data_saida"
        onChange={handleChange}
        required
      />
      {false && (
        <small style={{ color: 'var(--bs-danger)' }}>
          <small className="m-0" style={{ color: 'var(--bs-danger)' }}>
            A nota fiscal tem o prazo de apenas 5 dias, para trafegar dentro do estado de PE e 15 dias nos demais estados.
          </small>
        </small>
      )}
    </div>

    <div className="col-md-8 align-self-end "><small className="m-0" style={{ color: "var(--bs-danger)" }}>A nota fiscal tem o prazo de apenas 5 dias, para trafegar dentro do estado de PE e 15 dias nos demais estados.</small></div>


  </div>
}

export default Solicitante;