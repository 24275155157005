import React from "react";
import Destinatario from "./Destinatario";
import Solicitante from "./Solicitante";
import Endereco from "./Endereco";
import Transportadora from "./Transportadora";
import Produtos from "./Produtos";
import Finalizacao from "./Finalizacao";
import Conclusao from "./Conclusao";
import Footer from "./Footer";

const Emissao = () => {
  const [aceite, setAceite] = React.useState(false);
  const [avisoNota, setAvisoNota] = React.useState(false);

  const [sugestao, setSugestao] = React.useState([]);

  const [products, setProducts] = React.useState([]);
  
  const [solicitante, setSolicitante] = React.useState({
    nome_completo: "",
    telefone_whatsapp: "",
    email: "",
    data_saida: ""
  });

  const [destinatario, setDestinatario] = React.useState({
    cpf_cnpj: "",
    inscricao_estadual: "",
    nome_razao_social: "",

  });

  const [endereco, setEndereco] = React.useState({
    cep: "",
    estado: "",
    cidade: "",
    bairro: "",
    complemento: "",
    endereco: "",
    n: "",
  });

  const [transportadora, setTransportadora] = React.useState({
    nome_da_transportadora: "",
    quantidade_de_volumes: "",
    peso: "",
    frete_destinatario: "",
    infComplementares: "",
    telefone_de_contato_para_entrega_da_mercadoria: ""
  });

  const validaSaida = () => {


    let day = solicitante.data_saida.substring(0,2);
    let month = solicitante.data_saida.substring(3,5);
    let year = solicitante.data_saida.substring(6,10);

    let dataSaida = new Date(`${year}-${month}-${day} 00:00:00`);
    console.log(dataSaida)

    let dataAtual = new Date();
    dataAtual.setHours(0,0,0,0);

    // definir data limite 
    let dataLimite = new Date();
    dataLimite.setDate(dataAtual.getDate() + (parseInt(config.data_saida)) ); 
 
    dataLimite.setHours(0,0,0,0);


    if (dataLimite > dataSaida)
    {
        setAvisoNota(false)
        return true;
    } 

    setAvisoNota(true)
    return false;
    
    
  }

  const clear = () => {
    setSelecionados('')
  }

  const isDateValid = (inputDate) => {
    const [day, month, year] = inputDate.split('/');
    const formattedDate = `${month}/${day}/${year}`;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const parsedInputDate = new Date(formattedDate);
    const diffTime = parsedInputDate - today;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    console.log(inputDate)
    console.log(parsedInputDate)
    
    const maxDaysAhead = config.data_saida; // Máximo de dias à frente permitido

    return (
      parsedInputDate >= today &&
      diffDays <= maxDaysAhead &&
      !isNaN(parsedInputDate)
    );
  };

  function handleSubmit(event) {
    event.preventDefault();


    (checkCPF === true && isDateValid(solicitante.data_saida) && setStep(2)) 
  }

  const [produtos, setProdutos] = React.useState("");
  const [selecionados, setSelecionados] = React.useState({});
  const [total, setTotal] = React.useState("");
  const [result, setResult] = React.useState("");

  function enviar() {
    document.querySelector("#nextFinalizar").disabled = true;

    let solicitanteNovo = solicitante;

    let day = solicitanteNovo.data_saida.substring(0,2)
    let month = solicitanteNovo.data_saida.substring(3,5)
    let year = solicitanteNovo.data_saida.substring(6,10)

    let newDate = `${year}-${month}-${day}`;

    solicitanteNovo.data_saida = newDate

    let dados = {
      solicitante: solicitante,
      destinatario: destinatario,
      endereco: endereco,
      transportadora: transportadora,
      selecionados: selecionados,
      total: total,
    };

    fetch("https://soscidadao.online/wp-json/api/emissao/", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: dados,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        setResult(result);
        setStep(4);
      })
      .catch((err) => {
       // document.querySelector("#nextFinalizar").disabled = false;
      });
  }

  const [config, setConfig] = React.useState(null);

  React.useEffect(() => {
    produtos.length === 0 &&
      fetch(`https://soscidadao.online/wp-json/api/produto/`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {

          let produtosls = json.sort(function (a, b) {
            if (a.id > b.id) {
              return 1;
            }
            if (a.id < b.id) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          setProdutos(Object.values(produtosls));


          return json;
        });
  }, [produtos]);


  React.useEffect(() => {
    config === null &&
      fetch(`https://soscidadao.online/wp-json/api/config`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setConfig(json);
          return json;
        });
  }, );

  const [step, setStep] = React.useState(0);


 const [checkCPF, setCheckCPF] = React.useState(true);


  return ( <>
   
    { config &&  <><section className={` container d-flex`}>
        <div className="card my-3 col-12">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
           
              <li className="nav-item">
                <a
                  className={
                    "nav-link  " + (step === 0 ? "active" : "disabled")
                  }
                  href="/"
                >
                  Condições Gerais
                </a>
              </li>

              { step === 0 && <li class="nav-item">
                    <a class="nav-link" href="/status">Status do Consultor</a>
              </li> }

            { step !== 0 &&  <><li className="nav-item">
                <a
                  className={
                    "nav-link  " + (step === 1 ? "active" : "disabled")
                  }
                  href="/"
                >
                  Informações
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    "nav-link  " + (step === 2 ? "active" : "disabled")
                  }
                  href="/"
                >
                  Produtos
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    "nav-link disabled " + (step === 3 ? "active" : "")
                  }
                  href="/"
                >
                  Confirmação
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={
                    "nav-link disabled " + (step === 4 ? "active" : "")
                  }
                  href="/"
                >
                  Conclusão
                </a>

               
              </li> </>}

            
            </ul>

          </div>
          { avisoNota && <small class="alert alert-warning row container m-auto col-12 m-3" role="alert">A Nota Fiscal a partir de emissão, tem o prazo máximo de {config.data_saida} dias para trafegar dentro do estado de PE.</small> }
          {step === 0 && (
            <>
              <div className="card-body">
               
               {config &&  <div dangerouslySetInnerHTML={{ __html: config.termos_condicoes }} /> }
  
              </div>
              <div className="card-footer  d-flex justify-content-between">
              {config &&   <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="name"
                    checked={!!aceite}
                    id="aceite"
                    onChange={(e) => setAceite(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="aceite">
                    Concordo com termos e condições.
                  </label>
                </div> }

               

                {aceite && (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => setStep(1)}
                  >
                    Continuar
                  </button>
                )}
              </div>
            </>
          )}

          {step === 1 && (
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                    <Solicitante
                    validaSaida={validaSaida}
                    solicitante={solicitante}
                    avisoNota={avisoNota}
                    setSolicitante={setSolicitante}
                    />

                    <Destinatario
                     setCheckCPF={setCheckCPF}
                     checkCPF={checkCPF}
                    destinatario={destinatario}
                    setDestinatario={setDestinatario}
                    />

                    <Endereco endereco={endereco} setEndereco={setEndereco} />

                    <Transportadora
                    transportadora={transportadora}
                    setTransportadora={setTransportadora}
                    step={step}
                    setStep={setStep}
                    />
              </div>
              { avisoNota && <small class="alert alert-warning row container m-auto col-12 m-3" role="alert">A Nota Fiscal a partir de emissão, tem o prazo máximo de {config.data_saida} dias para trafegar dentro do estado de PE.</small> }
          
              <div className="card-footer  d-flex justify-content-between">
              <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => setStep(0)}
                  >
                    Voltar
                  </button>


                <button type="submit" className="btn btn-primary btn-sm" >
                  Próximo
                </button>
              </div>
            </form>
          )}


          {step === 2 && <Produtos config={config} setProducts={setProducts} products={products} setSugestao={setSugestao} sugestao={sugestao} setProdutos={setProdutos} produtos={produtos} step={step}
                setStep={setStep} setSelecionados={setSelecionados} setTotal={setTotal} />}

          {step === 3 && <Finalizacao config={config} selecionados={selecionados}  setSelecionados={setSelecionados} enviar={enviar} setStep={setStep} step={step}/>  }

          {step === 4 && <Conclusao result={result} config={config}  selecionados={selecionados} /> }

    
        </div>

      </section>

<Footer />
</>}</>
  );
};

export default Emissao;
