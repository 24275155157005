import React from "react";

const Produtos = (props) => {
  const { setProducts, products, setSugestao, sugestao } = props;

  async function handleSubmit(event) {
    event.preventDefault();

    //let produtos = Object.values(props.produtos);
    let selecionados = [];
    products.forEach((item) => {
      if (item.quantidade > 0 && item.preco > 0) {
        item.total = item.quantidade * item.preco;
        item.imposto = (item.total * props.config.taxa_imposto) / 100;

        let produto = {
          id: item.id,
          descricao: item.descricao,
          quantidade: item.quantidade,
          preco: item.preco,
          total: item.total,
          imposto: item.imposto,
        };
        selecionados.push(produto);
      }
    });

    props.setSelecionados(selecionados);

    let totalCalc = Imposto(selecionados) + Number(props.config.taxa_servico);

    let total = {
      imposto: "R$ " + Imposto(selecionados).toFixed(2),
      taxa_servico: "R$ " + props.config.taxa_servico,
      total: "R$ " + totalCalc.toFixed(2),
    };
    props.setTotal(total);
    selecionados.length > 0 && props.setStep(props.step + 1);
  }

  function Imposto(p) {
    let sum = 0;
    p.forEach((item) => {
      sum += item.imposto;
    });
    return sum;
  }

  function showSuggest(id) {
    let todosProdutos = props.produtos;
    let index = todosProdutos.findIndex(
      (produto) => produto.id === parseInt(id)
    );
    let produto = todosProdutos[index];

    let sugest = sugestao;

    if (produto.quantidade && produto.quantidade.length > 0) {
      sugest[id] = true;
    } else {
      sugest[id] = false;
    }
    setSugestao(sugest);
  }

  const handleDelete = (item) => {
    // Filtrar os produtos que não correspondem ao item que você deseja excluir
    const updatedProducts = products.filter((product) => product !== item);

    // Verificar se o comprimento do array filtrado é igual ao comprimento do array original
    if (updatedProducts.length === products.length) {
      // Isso significa que nenhum item foi removido
      // Você pode tratar isso como uma condição de erro ou fazer outra ação
      console.log("Nenhum item foi removido.");
    } else {
      // Atualizar o estado com o novo array de produtos
      setProducts(updatedProducts);
    }
  };

  const handleChange2 = (event, index) => {
    const { name, value, classList } = event.target;
    const updatedProducts = [...props.products];

    if (classList.contains("preco")) {
      updatedProducts[index].preco = value;
    } else {
      // Remova todos os caracteres que não são dígitos (0-9)
      const inputValue = value.replace(/\D/g, "");
      updatedProducts[index].quantidade = inputValue;
    }

    setProducts(updatedProducts);

    document
      .querySelectorAll(
        "#root > div > section > div > form > table > tbody > tr"
      )
      .forEach((e) => {
        if (e.childNodes[2].childNodes[0].value > 0) {
          e.childNodes[3].childNodes[1].style.display = "block";
        } else {
          e.childNodes[3].childNodes[1].style.display = "none";
        }
      });
  };

  React.useEffect(() => {
    props.setSelecionados("");
  });

  return (
    <>
      <form onSubmit={handleSubmit} className="p-2 produtos overflow-auto">
        <table className="table table-bordered ">
          <thead>
            <tr>
              <th scope="col">N° Item</th>
              <th scope="col">Descrição Item Pauta da Fiscal</th>
              <th scope="col">Quantidade</th>
              <th scope="col">Valor Unitário(R$)</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {products?.map((item, index) => (
              <tr key={index} className={"c-" + item.id}>
                <th scope="row">#{item.id}</th>
                <td>{item.descricao}</td>
                <td>
                  <input
                    type="number"
                    min="0"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    name={index}
                    value={item.quantidade}
                    className="quantidade"
                    onChange={(event) => handleChange2(event, index)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="preco"
                    value={item.preco || ''}
                    data-hash={item.hash}
                    step="0.01"
                    min={item.preco_minimo.toFixed(2)}
                    onChange={(event) => handleChange2(event, index)}
                  />

                  <small className={"min-" + item.id}>
                    Preço minímo{" "}
                    {item.preco_minimo.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "BRL",
                    })}
                  </small>
                </td>

                <td>
                  <button
                    className="buttonCloseList"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleDelete(item);
                    }}
                  >
                    x
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button
          type="submit"
          style={{ display: "none" }}
          className="btn btn-primary m-2"
          id="back-produto"
        >
          Voltar
        </button>
        <button
          type="submit"
          id="next-produto"
          style={{ display: "none" }}
          className="btn btn-primary m-2 btn-secondary"
        >
          Proxímo
        </button>
      </form>

      <form className="px-2 py-6" style={{ margin: "0 0 16px 0" }}>
        <select
          className="form-select"
          placeholder="Selecione por favor"
          onChange={(event) => {
            const selectedItemId = event.target.value;

            const productsCopy = props.produtos.map((item) => ({ ...item }));
            let selectedItem = productsCopy.find(
              (item) => Number(item.id) === Number(selectedItemId)
            );
            setProducts([...products, selectedItem]);

            event.target.value = "";
          }}
        >
          <option disabled value="" selected>
            Clique para selecionar o produto{" "}
          </option>

          {props.produtos[0] &&
            props.produtos.map((item, index) => (
              <option value={item.id} key={index}>
                {item.id} - {item.descricao}
              </option>
            ))}
        </select>
      </form>

      <div className="card-footer  d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => props.setStep(props.step - 1)}
        >
          Voltar
        </button>

        <div>
          <button
            type="submit"
            className="btn-primary btn btn-sm"
            onClick={() => document.querySelector("#next-produto").click()}
          >
            Continuar
          </button>
        </div>
      </div>
    </>
  );
};

export default Produtos;
