import React from "react";
import Footer from "../Components/Footer";
import Imprimir from "../Components/Imprimir";

const Status = () => {

  const [config, setConfig] = React.useState(null);


  const [protocolo, setProtocolo] = React.useState("");

  const [dados, setDados] = React.useState({});

  const handleChange = ({ target }) => {
    const { value } = target;
    setProtocolo(value);
  };

  async function consultar() {
    ;

    protocolo && await fetch(
      `https://soscidadao.online/wp-json/api/emissao/?protocolo=${protocolo}`
    )
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json[0]) {
          setDados(json[0]);
        } else {
          setDados(null);
        }

        console.log(dados);
        return json;
      });
  }

  React.useEffect(() => {
    if (protocolo === "") {
      setDados("");
    }
  }, [protocolo]);

  React.useEffect(() => {
  config === null &&
  fetch(`https://soscidadao.online/wp-json/api/config`)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      setConfig(json);
      return json;
    });

  }, []);


  return (
    <>
      <section className={` container d-flex`}>
        <div className="card mt-3 mb-3 col-12">
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
              <a className="nav-link" href="/">
                  Condições Gerais
                </a></li>
                <li className="nav-item">
                <a className="nav-link active" href="/status">
                  Status do Consultor
                </a>
              </li>
            </ul>
          </div>

          {dados === null && protocolo && (
            <small class="alert alert-warning " role="alert">
              Protocolo não encontrado!
            </small>
          )}

          <div className="card-body">
            <div className="input-group px-5 pb-2">
              <input
                type="search"
                id={protocolo}
                onChange={handleChange}
                className="form-control rounded"
                style={{ border: "1px solid #dfdfdf" }}
                placeholder="Digite seu n° de protocolo"
                aria-label="Search"
                aria-describedby="search-addon"
              />
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => consultar()}
              >
                Consultar
              </button>
            </div>
          </div>

          {dados && protocolo && (
            <ul className="list-group mx-5 pb-5 border-0 ">
              <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="">
                  <h6 className="my-0">
                    Solicitação n° {dados && dados["solicitacao"].protocolo}
                  </h6>
                </div>
                <span>Status: {dados && dados["solicitacao"].status}</span>
              </li>

             {dados["solicitacao"]["nota_fiscal"] &&  <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="">
                  <h6 className="my-0">Nota fiscal disponível: </h6>
                </div>
                <span><a  href={dados && dados["solicitacao"]["nota_fiscal"]} target="_blank" class="btn btn-success btn-sm" rel="noreferrer"  download="notafiscal.pdf">Nota Fiscal</a></span>
              </li> }

              {dados["solicitacao"]["boleto"] &&  <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="">
                  <h6 className="my-0">Boleto disponível: </h6>
                </div>
                <span><a  href={dados && dados["solicitacao"]["boleto"]} target="_blank" class="btn btn-success btn-sm" rel="noreferrer"  download="boleto.pdf">Boleto</a></span>
              </li> }

              {dados["solicitacao"]["comprovante"] &&  <li className="list-group-item d-flex justify-content-between bg-light">
                <div className="">
                  <h6 className="my-0">Comprovante de pagamento do boleto referente o imposto: </h6>
                </div>
                <span><a  href={dados && dados["solicitacao"]["comprovante"]} target="_blank" class="btn btn-success btn-sm" rel="noreferrer"  download="comprovante.pdf">Comporvante</a></span>
              </li> }



              {config &&  <li className="list-group-item d-flex justify-content-between bg-light flex-column" dangerouslySetInnerHTML={{ __html: config.dados_pagamento}} /> }

              {config &&  <li className="dados_bancarios_li list-group-item d-flex justify-content-between lh-condensed flex-column" dangerouslySetInnerHTML={{ __html: config.dados_bancarios}} /> }



              
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="">
                  <h6 className="my-0">Imposto da nota: </h6>
                </div>
                <span>{dados && dados["solicitacao"]["total_do_imposto"]}</span>
              </li>

              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="">
                  <h6 className="my-0">Taxa de serviço: </h6>
                </div>
                <span>{dados && dados["solicitacao"]["taxa_de_servico"]}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between bg-light">
                <span>Total a pagar (BRL)</span>
                <strong>{dados && dados["solicitacao"].total_a_pagar}</strong>
              </li>
            </ul>
          )}

        { dados &&  <Imprimir /> }
        </div>

  
      </section>

    


<Footer />
    </>
  );
};

export default Status;
