import React from "react";

const Finalizacao = (props) => {

  const total = () => {
    let sum = 0;
    props.selecionados && props.selecionados.forEach((item) => {
      sum += item.imposto;
    });

    return sum;
  };

  const totalPagar = () => {
    let sum = Number(props.config.taxa_servico);
    props.selecionados && props.selecionados.forEach((item) => {
      sum += item.imposto;
    });

    return sum;
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    }).format(value);

  return (
    <>
      <form className='p-2'>
        <ul className="list-group ">
          <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div class="table-responsive col-12">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th scope="col">N° Item</th>
                    <th scope="col">Descrição Item Pauta da Fiscal</th>
                    <th scope="col">Quantidade</th>
                    <th scope="col">Valor Unitário</th>
                    <th scope="col">Valor Total</th>
                    <th scope="col">Imposto ( {props.config.taxa_imposto}% )</th>
                  </tr>
                </thead>

                <tbody>
                  {props.selecionados[0] &&
                    props.selecionados.map((item) => (
                      <tr key={item.id}>
                        <th scope="row">{item.id}</th>
                        <td>{item.descricao}</td>
                        <td>{item.quantidade}</td>
                        <td>{parseFloat(item.preco).toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</td>
                        <td>{parseFloat(item.total).toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</td>
                        <td>{item.imposto.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </li>

          <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div>
              <h6 className="my-0">Total do Imposto:</h6>

            </div>
            <span className="text-muted">{numberFormat(total())}</span>
          </li>
          <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div>
              <h6 className="my-0">Taxa de Serviço:  </h6>
              <small className="text-muted"></small>
            </div>
            <span className="text-muted">R$ {props.config.taxa_servico}</span>
          </li>

          <li className="list-group-item d-flex justify-content-between">
            <span>Total a pagar: (BRL)</span>
            <strong>{numberFormat(totalPagar())}</strong>
          </li>
        </ul>
      </form>

      <div className="card-footer  d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => {
            props.setStep(props.step - 1)
          }}
        >
          Voltar
        </button>
        <button
          type="button"
          id="nextFinalizar"
          className="btn btn-primary btn-sm"
          onClick={() => props.enviar()}
        >
          Confirmar
        </button>
      </div>

    </>
  );
};

export default Finalizacao;
